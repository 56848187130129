<template>
  <div class="container mt-4">
    <Breadcrumb>
      <template slot="restPage">
        <el-breadcrumb-item>
          <router-link
            :to="{ name: 'SATResults', query: { type: practiceType } }"
          >
            {{ $t("sat.SAT_Results") }}
          </router-link>
        </el-breadcrumb-item>
        <el-breadcrumb-item v-if="exam">
          {{ getCompanyExamTitle(exam.title) }}
        </el-breadcrumb-item>
      </template>
    </Breadcrumb>
    <div v-if="result">
      <div v-if="result.is_finished && result.is_finished == 1">
        <el-alert
          :title="$t('courses.completed')"
          type="success"
          center
          show-icon
          :closable="false"
        >
          <div slot="title">
            {{ $t("courses.completed") }}
            <el-button
              v-if="isRoleAdmin()"
              class="ml-2"
              type="warning"
              size="small"
              @click="setExamSectionShow()"
            >
              <i class="fa fa-play"></i>
              考試有問題？開放給用戶從指定單元繼續做。
            </el-button>
            <el-dialog
              title="請選繼續的單元"
              :visible.sync="examSectionShow"
              width="50%"
            >
              <div>
                <el-form ref="form" :model="form" label-width="80px">
                  <!-- <el-form-item label="当前单元">
                    <el-input v-model="form.name"></el-input>
                  </el-form-item> -->
                  <el-form-item label="調整到">
                    <el-select
                      v-model="setSectionOrder"
                      placeholder="请选择"
                      style="width: 100%;"
                    >
                      <template
                        v-if="
                          practiceType === 'composePractices' ||
                            practiceType === 'composePractice'
                        "
                      >
                        <el-option
                          :label="`English Module 1`"
                          :value="1"
                        ></el-option>
                        <el-option
                          :label="`English Module 2`"
                          :value="2"
                        ></el-option>
                        <el-option
                          :label="`Math Module 1`"
                          :value="3"
                        ></el-option>
                        <el-option
                          :label="`Math Module 2`"
                          :value="4"
                        ></el-option>
                      </template>
                      <template v-else>
                        <el-option :label="`Module 1`" :value="1"></el-option>
                      </template>
                    </el-select>
                  </el-form-item>
                </el-form>
              </div>
              <span slot="footer" class="dialog-footer">
                <el-button @click="examSectionShow = false">取 消</el-button>
                <el-button type="primary" @click="setExamSection()"
                  >確 定</el-button
                >
              </span>
            </el-dialog>
          </div>
        </el-alert>
      </div>
      <div v-else>
        <el-alert
          :title="$t('courses.Incomplete')"
          type="error"
          center
          show-icon
          :closable="false"
        >
          <div slot="title">
            {{ $t("courses.Incomplete") }}
            <el-button
              v-if="isRoleAdmin()"
              class="ml-2"
              type="primary"
              size="small"
              @click="setExamFinished()"
            >
              <i class="fa fa-user-check"></i>
              设置當前考試為完成狀態
            </el-button>
          </div>
        </el-alert>
      </div>
      <el-alert
        v-if="
          this.leaveTimes > 0 ||
            (result.other_status.timeType && result.other_status.timeType > 1)
        "
        class="mt-2"
        type="info"
        center
        show-icon
        :closable="false"
      >
        <div slot="title">
          <span
            v-if="this.leaveTimes > 0"
            v-html="
              $t('toefl.leaveAlertResult', {
                counts: this.leaveCountText,
                times: this.leaveSeconds,
                second: this.second
              })
            "
          ></span>
          <el-tag
            v-if="
              result.other_status.timeType && result.other_status.timeType > 1
            "
            class="ml-2"
            size="mini"
            type="info"
            effect="dark"
          >
            <b>{{ timeTypeArr[result.other_status.timeType] }}</b>
          </el-tag>
        </div>
      </el-alert>
    </div>
    <ScoreHistory v-if="false"></ScoreHistory>
    <div class="row sat">
      <router-link
        :to="{
          name: 'SATList'
        }"
        class="col-md-4 sat_left print_div"
      >
        <p>SAT</p>
        <span>®</span>
      </router-link>
      <div class="col-md-8 sat_right row">
        <div class="ml-3" v-if="result">
          <ul>
            <li>
              <b>Name: </b>
              <router-link
                :to="{ name: 'AdminViewUser', params: { id: result.user.id } }"
                v-if="isAdmin"
              >
                {{
                  user.displayName(
                    result.user.first_name,
                    result.user.last_name
                  )
                }}
              </router-link>
              <span v-else>
                {{
                  user.displayName(
                    result.user.first_name,
                    result.user.last_name
                  )
                }}
              </span>
            </li>
            <li>
              <b>Total Score: </b>
              <template v-if="practiceType == 'drill'">
                <b>
                  <span style="color: var(--themeColor);">{{
                    correctCount
                  }}</span
                  >/{{ count }}
                </b>
              </template>
              <template v-else>
                <b style="color: var(--themeColor);">
                  {{ result.total_score }}
                </b>
                <b>
                  (<span style="color: var(--themeColor);">{{
                    correctCount
                  }}</span
                  >/{{ count }})
                </b>
              </template>
            </li>
            <li>
              <b>Test Name: </b>
              <span>
                <el-tag class="mr-2" type="warning" v-if="isAdaptive">
                  <b>Adaptive</b>
                </el-tag>
                {{ getCompanyExamTitle(exam.title) }}
              </span>
              <el-tooltip
                v-if="isAdmin"
                class="ml-2"
                effect="dark"
                content="Regrade"
                placement="top"
              >
                <el-button
                  @click="reGrading(result.id)"
                  type="warning"
                  size="mini"
                >
                  <i class="fas fa-redo"></i>
                </el-button>
              </el-tooltip>
            </li>
            <li>
              <b>Test Date: </b>
              <el-tooltip
                class="item"
                effect="dark"
                :content="moment(result.updated_at).format('HH:mm')"
                placement="top"
              >
                <span> {{ instant.formatGoogleTime(result.updated_at) }}</span>
              </el-tooltip>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <ScoreTable
      v-if="result && result.result"
      :total="result.total_score"
      :score="result.result"
      :practiceType="practiceType == 'drill' ? 'singlePractice' : practiceType"
      :isNew="isNew"
      :tag_analysis="tag_analysis_arr_score"
    ></ScoreTable>
    <template v-if="practices">
      <div v-for="(practice, passageIndex) in practices" :key="practice.id">
        <hr />
        <UserTestResultTable
          :examId="exam.id"
          :list="[practice]"
          :score="100"
          :passageIndex="passageIndex"
          :raw="100"
          :count="100"
          :userExamId="userExamId"
          :practiceType="
            practiceType == 'drill' ? 'singlePractice' : practiceType
          "
        ></UserTestResultTable>
      </div>
      <div class="mb-4" v-show="tag_analysis && tag_analysis.length > 0">
        <hr />
        <h5 class="title">
          My Performance Breakdown
        </h5>
        <div :class="isFree ? 'performance' : ''">
          <template v-if="isFree">
            <div class="blurBoxCoverFirst"></div>
            <el-card class="blurBoxCover box-card">
              <template v-if="isTeacher || isAdmin || isCompanyAdmin">
                This is a free test. You can unlock it for the student to see
                explanations and skills performance.
                <div class="text-center mt-3">
                  <el-button @click="unlockResult" type="primary">
                    <font-awesome-icon icon="fas fa-unlock" />
                    Unlock
                  </el-button>
                </div>
              </template>
              <template v-else>
                <font-awesome-icon icon="fas fa-lock" />
                To see your performance, find out your weaknesses, and review
                this test with a professional tutor, please contact:
                <div class="companyInfo">
                  <div class="companyInfoItem">
                    <span class="companyInfoText">{{ CompanyName }}</span>
                  </div>
                  <div class="companyInfoItem">
                    <span class="companyInfoText">{{ CompanyEmail }}</span>
                  </div>
                </div>
              </template>
            </el-card>
            <div
              v-for="(tag_analysis, key, index) in mockData"
              :key="index"
              class="mb-4"
            >
              <Performance
                v-if="tag_analysis && tag_analysis.length > 0"
                section="Reading"
                :tagType="key"
                :tags="tag_analysis"
              ></Performance>
            </div>
          </template>
          <template v-else>
            <div
              v-for="(tag_analysis, key, index) in tag_analysis_arr"
              :key="index"
              class="mb-4"
            >
              <Performance
                v-if="tag_analysis && tag_analysis.length > 0"
                section="Reading"
                :tagType="key.toUpperCase()"
                :tags="tag_analysis"
              ></Performance>
            </div>
          </template>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import _ from "lodash";
import { user, instant } from "@ivy-way/material";
import Breadcrumb from "@/components/Breadcrumb";
import SAT from "@/apis/sat";
import UserTestResultTable from "@/views/satUserTestResult/components/UserTestResultTable.vue";
import ScoreTable from "@/views/satUserTestResult/components/ScoreTable.vue";
import ScoreHistory from "@/views/satUserTestResult/components/ScoreHistory.vue";
import Performance from "@/views/satUserTestResult/performance/Index.vue";
import moment from "moment";
import roleMixin from "@/mixins/role";

export default {
  metaInfo() {
    return {
      title: "TestResults - " + this.CompanyName
    };
  },

  components: {
    Breadcrumb,
    UserTestResultTable,
    ScoreTable,
    ScoreHistory,
    Performance
  },

  mixins: [roleMixin],

  props: [],
  data() {
    return {
      examSectionShow: false,
      setSectionOrder: 1,
      mockData: {
        "Content Domain": [
          {
            sat_question_tag_id: 1,
            user_correct_count: 12,
            user_question_count: 44,
            user_sample_count: 8,
            total_correct_count: 28265,
            total_question_count: 47203,
            total_sample_count: 3904,
            sat_question_tag_type: "Content Domain",
            sat_question_tag_name: "Craft and Structure",
            correct_count: 26,
            question_count: 26
          },
          {
            sat_question_tag_id: 10,
            user_correct_count: 10,
            user_question_count: 19,
            user_sample_count: 3,
            total_correct_count: 18762,
            total_question_count: 27811,
            total_sample_count: 3636,
            sat_question_tag_type: "Content Domain",
            sat_question_tag_name: "Expression of Ideas",
            correct_count: 6,
            question_count: 6
          },
          {
            sat_question_tag_id: 3,
            user_correct_count: 8,
            user_question_count: 20,
            user_sample_count: 1,
            total_correct_count: 27122,
            total_question_count: 45169,
            total_sample_count: 3938,
            sat_question_tag_type: "Content Domain",
            sat_question_tag_name: "Information and Ideas",
            correct_count: 7,
            question_count: 7
          },
          {
            sat_question_tag_id: 21,
            user_correct_count: 2,
            user_question_count: 3,
            user_sample_count: 3,
            total_correct_count: 163,
            total_question_count: 392,
            total_sample_count: 148,
            sat_question_tag_type: "Content Domain",
            sat_question_tag_name: "Advanced Math",
            correct_count: 1,
            question_count: 2
          },
          {
            sat_question_tag_id: 25,
            user_correct_count: 0,
            user_question_count: 5,
            user_sample_count: 3,
            total_correct_count: 257,
            total_question_count: 474,
            total_sample_count: 154,
            sat_question_tag_type: "Content Domain",
            sat_question_tag_name: "Problem-Solving and Data Analysis",
            correct_count: 11,
            question_count: 12
          }
        ],
        "Skill/Knowledge Testing Points": [
          {
            sat_question_tag_id: 2,
            user_correct_count: 125,
            user_question_count: 172,
            user_sample_count: 8,
            total_correct_count: 32989,
            total_question_count: 52145,
            total_sample_count: 5041,
            sat_question_tag_type: "Skill/Knowledge Testing Points",
            sat_question_tag_name: "Words in Context",
            correct_count: 13,
            question_count: 13
          },
          {
            sat_question_tag_id: 5,
            user_correct_count: 84,
            user_question_count: 101,
            user_sample_count: 2,
            total_correct_count: 29444,
            total_question_count: 47059,
            total_sample_count: 5056,
            sat_question_tag_type: "Skill/Knowledge Testing Points",
            sat_question_tag_name: "Command of Evidence (Textual)",
            correct_count: 5,
            question_count: 5
          },
          {
            sat_question_tag_id: 12,
            user_correct_count: 5,
            user_question_count: 8,
            user_sample_count: 3,
            total_correct_count: 11968,
            total_question_count: 17492,
            total_sample_count: 4523,
            sat_question_tag_type: "Skill/Knowledge Testing Points",
            sat_question_tag_name: "Transitions",
            correct_count: 6,
            question_count: 6
          },
          {
            sat_question_tag_id: 27,
            user_correct_count: 10,
            user_question_count: 23,
            user_sample_count: 7,
            total_correct_count: 21312,
            total_question_count: 25599,
            total_sample_count: 4036,
            sat_question_tag_type: "Skill/Knowledge Testing Points",
            sat_question_tag_name: "Percentages",
            correct_count: 11,
            question_count: 12
          },
          {
            sat_question_tag_id: 8,
            user_correct_count: 19,
            user_question_count: 32,
            user_sample_count: 8,
            total_correct_count: 15167,
            total_question_count: 26413,
            total_sample_count: 4789,
            sat_question_tag_type: "Skill/Knowledge Testing Points",
            sat_question_tag_name: "Text Structure and Purpose",
            correct_count: 12,
            question_count: 12
          },
          {
            sat_question_tag_id: 9,
            user_correct_count: 10,
            user_question_count: 17,
            user_sample_count: 5,
            total_correct_count: 13639,
            total_question_count: 19831,
            total_sample_count: 4619,
            sat_question_tag_type: "Skill/Knowledge Testing Points",
            sat_question_tag_name: "Cross-Text Connections",
            correct_count: 1,
            question_count: 1
          },
          {
            sat_question_tag_id: 4,
            user_correct_count: 8,
            user_question_count: 14,
            user_sample_count: 3,
            total_correct_count: 15721,
            total_question_count: 24664,
            total_sample_count: 4812,
            sat_question_tag_type: "Skill/Knowledge Testing Points",
            sat_question_tag_name: "Central Ideas and Details",
            correct_count: 3,
            question_count: 3
          },
          {
            sat_question_tag_id: 22,
            user_correct_count: 36,
            user_question_count: 57,
            user_sample_count: 3,
            total_correct_count: 16629,
            total_question_count: 20173,
            total_sample_count: 3751,
            sat_question_tag_type: "Skill/Knowledge Testing Points",
            sat_question_tag_name: "Nonlinear functions",
            correct_count: 1,
            question_count: 2
          },
          {
            sat_question_tag_id: 32,
            user_correct_count: 11,
            user_question_count: 14,
            user_sample_count: 2,
            total_correct_count: 4308,
            total_question_count: 5804,
            total_sample_count: 2982,
            sat_question_tag_type: "Skill/Knowledge Testing Points",
            sat_question_tag_name: "Circles",
            correct_count: 0,
            question_count: 1
          },
          {
            sat_question_tag_id: 6,
            user_correct_count: 17,
            user_question_count: 35,
            user_sample_count: 6,
            total_correct_count: 10523,
            total_question_count: 16200,
            total_sample_count: 4225,
            sat_question_tag_type: "Skill/Knowledge Testing Points",
            sat_question_tag_name: "Command of Evidence (Quantitative)",
            correct_count: 9,
            question_count: 9
          },
          {
            sat_question_tag_id: 7,
            user_correct_count: 16,
            user_question_count: 24,
            user_sample_count: 3,
            total_correct_count: 9814,
            total_question_count: 17193,
            total_sample_count: 4608,
            sat_question_tag_type: "Skill/Knowledge Testing Points",
            sat_question_tag_name: "Inferences",
            correct_count: 4,
            question_count: 4
          }
        ]
      },
      moment,
      exam: null,
      practices: null,
      result: null,
      tag_analysis: null,
      userAnswers: [],
      count: 0,
      isNew: false,
      leaveSeconds: 0,
      leaveTimes: 0,
      timeTypeArr: {
        "1.5": "Time and One-Half (+50%)",
        "2": "Double Time (+100%)",
        "0": "Not Timed (∞)"
      }
    };
  },
  computed: {
    ...mapState("user", ["lang"]),
    leaveCountText() {
      let times = "time";
      if (this.lang.value === "EN-US") {
        if (this.leaveTimes <= 1) {
          times = this.leaveTimes + " " + "time";
        } else {
          times = this.leaveTimes + " " + "times";
        }
      } else {
        times = this.leaveTimes + " " + "次";
      }
      return times;
    },
    second() {
      let second = "second";
      if (this.lang.value === "EN-US") {
        if (this.leaveSeconds <= 1) {
          second = "second";
        } else {
          second = "seconds";
        }
      } else {
        second = "秒";
      }
      return second;
    },
    user() {
      return user;
    },
    instant() {
      return instant;
    },
    practiceType() {
      return this.$route.query.practiceType === "adaptivePractices"
        ? "composePractices"
        : this.$route.query.practiceType;
    },
    isAdaptive() {
      return (
        this.result.other_status &&
        this.result.other_status.adaptive_practice_id
      );
    },
    userExamId() {
      return this.$route.params.id;
    },
    isAdmin() {
      let isAdmin = this.isRoleAdmin();
      return isAdmin;
    },
    isTeacher() {
      let isTeacher = this.isRoleTeacher();
      return isTeacher;
    },
    isCompanyAdmin() {
      let isCompanyAdmin = this.isRoleCompanyAdmin();
      return isCompanyAdmin;
    },
    isFree() {
      return (
        (this.result.other_status.from == "trial" ||
          this.result.other_status.from == "company_expend") &&
        this.result.other_status.trial_status == 0
      );
    },
    correctCount() {
      let correctCount = 0;
      if (this.practices) {
        this.practices.forEach(item => {
          correctCount += item.correctCount;
        });
      }
      return correctCount;
    },
    tag_analysis_arr() {
      let res = null;
      if (this.tag_analysis) {
        res = _.groupBy(this.tag_analysis, "sat_question_tag_subject");
      }

      let newObj = {
        english: [],
        math: [],
        unset: []
      };
      Object.keys(res).forEach(key => {
        if (key === "english") {
          newObj.english = res[key];
        }
        if (key === "math") {
          newObj.math = res[key];
        }
        // if (key === "unset") {
        //   newObj.unset = res[key];
        //   let unsetObj = _.groupBy(res[key], "sat_question_tag_type");
        //   newObj = {
        //     ...newObj,
        //     ...unsetObj
        //   };
        // }
        // console.log(key, res[key]);
      });

      return newObj;
    },
    tag_analysis_arr_score() {
      let res = null;
      if (this.tag_analysis) {
        res = _.groupBy(this.tag_analysis, "sat_question_tag_type");
      }

      let newObj = {
        english: [],
        math: []
      };
      Object.keys(res).forEach(key => {
        if (key === "Content Domain") {
          // newObj.unset = res[key];
          let unsetObj = _.groupBy(res[key], "sat_question_tag_subject");
          newObj = {
            ...newObj,
            ...unsetObj
          };
        }
        // console.log(key, res[key]);
      });

      return newObj;
    }
  },
  watch: {},

  async mounted() {
    if (
      this.practiceType === "composePractices" ||
      this.practiceType === "composePractice"
    ) {
      const res = await SAT.getComposePracticeResult(this.userExamId);
      this.exam = res.compose_practice.exam;
      this.practices = res.compose_practice.practices;
      this.result = res.result;
      this.isNew = res.is_new ? true : false;
      if (this.isAdaptive) {
        this.isNew = true;
      }
      this.tag_analysis = res.tag_analysis;
    } else if (
      this.practiceType === "singlePractice" ||
      this.practiceType === "practice" ||
      this.practiceType === "drill"
    ) {
      const res = await SAT.getPracticeResult(this.userExamId);
      this.exam = res.practice.exam;
      this.practices = [res.practice];
      this.result = res.result;
      this.tag_analysis = res.tag_analysis;
    }
    if (this.result.other_status && this.result.other_status.leaveInfo) {
      this.leaveSeconds = this.result.other_status.leaveInfo.leaveSeconds
        ? this.result.other_status.leaveInfo.leaveSeconds
        : 0;
      this.leaveTimes = this.result.other_status.leaveInfo.leaveTimes
        ? this.result.other_status.leaveInfo.leaveTimes
        : 0;
    }
    if (this.result.other_status && this.result.other_status.pastOrder) {
      this.setSectionOrder = this.result.other_status.pastOrder.section_order
        ? this.result.other_status.pastOrder.section_order
        : 1;
    }
    this.getUserAnswers();
  },
  methods: {
    async setExamFinished() {
      await SAT.setExamFinished(this.userExamId);
      this.$router.go(0);
    },
    async setExamSectionShow() {
      this.examSectionShow = true;
    },
    async setExamSection() {
      await SAT.setExamFinished(this.userExamId);
      await SAT.setExamSection(this.userExamId, {
        section_order: this.setSectionOrder,
        question_order: 1
      });
      this.$router.go(0);
    },
    reGrading(userExamId) {
      this.$confirm(
        "Are you sure you want to regrade this test?",
        this.$t("management.Regrade"),
        {
          confirmButtonText: this.$t("alert.confirm"),
          cancelButtonText: this.$t("alert.cancel"),
          type: "warning"
        }
      )
        .then(async () => {
          await SAT.reGradingUserExam(userExamId);
          this.$message({
            message: "Success!",
            type: "success"
          });
          this.$router.go(0);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: this.$t("alert.Canceled")
          });
        });
    },
    async unlockResult() {
      try {
        await this.$confirm("Unlock it for the student?", {
          confirmButtonText: this.$t("alert.confirm"),
          cancelButtonText: this.$t("alert.cancel"),
          type: "warning"
        });
      } catch (e) {
        return false;
      }

      try {
        await SAT.unlockResult(this.userExamId);
        this.$router.go(0);
      } catch (e) {
        return false;
      }
    },
    getUserAnswers() {
      let count = 0;
      this.practices.forEach(practice => {
        let arr = [];
        let correctCount = 0;

        practice.exam.exam_questions.forEach(question => {
          count++;
          let answer = null;
          this.result.user_exam_answers.forEach(userAnswer => {
            if (question.id === userAnswer.exam_question.id) {
              answer = userAnswer;
              if (userAnswer.is_correct === 1) {
                correctCount++;
              }
            }
          });
          question["userAnswer"] = answer;
          arr.push(answer);
        });
        practice["correctCount"] = correctCount;
        this.userAnswers.push(arr);
      });
      this.count = count;
    }
  }
};
</script>

<style scoped>
.performance {
  /* height: 600px; */
  position: relative;
  overflow: hidden;
}
.blurBoxCoverFirst {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  z-index: 998;
  background-color: white;
  opacity: 0.75;
}
.blurBoxCover {
  position: absolute;
  top: 200px;
  left: 50%;
  right: auto;
  bottom: auto;
  margin: auto;
  margin-left: -250px;
  /* height: 30%; */
  width: 500px;
  z-index: 999;
  background-color: white;
  padding: 1rem;
  /* box-shadow: 0 0 8rem 8rem white; */
  font-size: 1.1rem;
}

.companyInfo {
  margin: 1rem 0;
}
.companyInfoItem {
  display: flex;
  /* margin-bottom: 0.5rem; */
}
.companyInfoLabel {
  width: 5.1rem;
}
.companyInfoText {
  flex: 1;
  color: var(--themeColor);
  font-weight: 700;
}
.title {
  color: #494949;
  font-size: 1.5rem;
  border: 0;
  padding: 0;
  border-left: 4px solid var(--themeColor);
  padding-left: 15px;
  /* line-height: 16px; */
  margin-bottom: 20px;
}
.sat_left {
  display: flex;
  color: var(--themeColor);
  text-decoration: none;
}

.sat_left > span {
  font-size: 52px;
}
.sat_left > p:first-child {
  font-size: 140px;
  font-weight: 700;
  line-height: 140px;
  margin-bottom: 0px;
}
.sat_right {
  padding-left: 0px;
  padding-top: 30px;
  /* border-bottom: 1px solid#DCDFE6; */
}
.sat_right ul {
  clear: both;
}
.sat_right ul li {
  font-size: 20px;
  line-height: 30px;
}
.sat .sat_right ul {
  list-style: none;
  position: relative;
  padding-left: 0;
}
@media screen and (max-width: 768px) {
  .container {
    padding: 15px;
  }
  .sat_left {
    display: none;
  }

  .sat_right {
    padding-right: 15px;
    padding-left: 15px;
    padding-top: 1rem;
  }
  .sat_right ul li {
    font-size: 1rem;
    line-height: 1.5rem;
  }
}
</style>
