var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',_vm._l((_vm.list),function(passage,index){return _c('div',{key:index},[(_vm.practiceType === 'composePractices')?_c('h4',[_vm._v(" "+_vm._s(_vm.getCompanyExamTitle(passage.exam.title))+": "),_c('span',[_c('span',{staticStyle:{"color":"#ff8920"}},[_vm._v(_vm._s(passage.correctCount))]),_vm._v("/"+_vm._s(passage.exam.exam_questions.length))]),_c('span',{staticClass:"ml-2 text-info"},[_vm._v("["+_vm._s(_vm.sectionTotalTime)+"]")])]):_vm._e(),_vm._l((Math.ceil(passage.exam.exam_questions.length / _vm.lineCount)),function(i){return _c('div',{key:i,staticClass:"answer-table"},[_c('table',{staticClass:"table table-bordered"},[_vm._m(0,true),_c('tbody',_vm._l((passage.exam.exam_questions),function(question,index){return _c('tr',{directives:[{name:"show",rawName:"v-show",value:((i - 1) * _vm.lineCount <= index && index < i * _vm.lineCount),expression:"(i - 1) * lineCount <= index && index < i * lineCount"}],key:question.order},[_c('td',{staticClass:"green-td"},[_vm._v(_vm._s(question.order))]),_c('td',[_c('router-link',{attrs:{"to":{
                  name: 'QuestionExplanation',
                  params: {
                    questionId: question.question_id
                  },
                  query: {
                    user_exam_id: _vm.userExamId,
                    practiceType: _vm.practiceType,
                    order: index + 1,
                    passageIndex: _vm.passageIndex + 1,
                    user_exam_answer_id: question.userAnswer
                      ? question.userAnswer.id
                      : null
                  }
                }}},[(question.userAnswer)?[(question.userAnswer.answers[0])?[(question.userAnswer.is_correct === 1)?_c('div',{staticClass:"text-success"},[_c('i',{staticClass:"fa fa-check"})]):_c('div',{staticClass:"text-danger"},[_c('i',{staticClass:"fa fa-times"})])]:_c('div',{staticClass:"text-danger"},[_c('i',{staticClass:"fa fa-times"})])]:_c('b',{staticStyle:{"color":"#ccc"}},[_vm._v(" / ")])],2)],1),_c('td',[_c('router-link',{attrs:{"to":{
                  name: 'QuestionExplanation',
                  params: {
                    questionId: question.question_id
                  },
                  query: {
                    user_exam_id: _vm.userExamId,
                    practiceType: _vm.practiceType,
                    order: index + 1,
                    passageIndex: _vm.passageIndex + 1,
                    user_exam_answer_id: question.userAnswer
                      ? question.userAnswer.id
                      : null
                  }
                }}},[(question.userAnswer)?_c('span',{staticClass:"text-info"},[_vm._v(" "+_vm._s(question.userAnswer.other_status ? _vm.showTotalTime( question.userAnswer.other_status.activities ) : 1)+" ")]):_vm._e()])],1)])}),0)])])})],2)}),0)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('thead',[_c('tr',{staticClass:"rnum_1"},[_c('th',{staticClass:"green-td"},[_vm._v("#")]),_c('th',{staticClass:"green-td"},[_vm._v("Correct Answer")]),_c('th',{staticClass:"green-td"},[_vm._v("Answer Time")])])])}]

export { render, staticRenderFns }