<template>
  <div class="history">
    <h4 class="title" style="color:#43a06b;margin:20px 0 0 0">
      Score History
    </h4>
    <div style="overflow:auto">
      <div
        id="report"
        :style="{
          width: this.source.length * 150 + 20 + 'px',
          height: '200px',
          margin: 0
        }"
      ></div>
    </div>
  </div>
</template>
<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import Common from "@/mixins/common.js";
import echarts from "echarts";
export default {
  components: {},

  mixins: [Common],

  props: [],
  data() {
    return {
      charts: "",
      score: 1600,
      minScore: 0,
      source:[],
      history_total_score: [
        {
          title: "CB Khan SAT Test 07",
          user_exam_id: 4375,
          total_score: 1080,
          date: "2019-05-29"
        },
        {
          title: "201903 SAT Test (US)",
          user_exam_id: 4378,
          total_score: 1130,
          date: "2019-07-04"
        },
        {
          title: "201905 May SAT (US)",
          user_exam_id: 4381,
          total_score: 1130,
          date: "2019-07-11"
        },
        {
          title: "201904 SAT Test (US)",
          user_exam_id: 4379,
          total_score: 1160,
          date: "2019-07-18"
        },
        {
          title: "201905 May SAT (IN)",
          user_exam_id: 4380,
          total_score: 1290,
          date: "2019-07-25"
        },
        {
          title: "201812 December SAT (US)",
          user_exam_id: 4377,
          total_score: 1290,
          date: "2019-08-01"
        },
        {
          title: "201812 SAT OCT(IN)",
          user_exam_id: 4376,
          total_score: 1360,
          date: "2019-08-08"
        },
        {
          title: "201705 SAT Test (US)",
          user_exam_id: 17914,
          total_score: 400,
          date: "2022-02-10"
        }
      ]
    };
  },
  computed: {},
  watch: {},

  mounted() {
    this.$nextTick(function() {
      this.draw("report");
    });
    let source = [];
    let minScore = 1600;
    this.history_total_score.forEach((val, index) => {
      if (val.total_score < minScore) {
        minScore = val.total_score;
      }
      source.push([
        index + 1 + ". " + val.date,
        val.total_score,
        val.date,
        val.user_exam_id,
        val.title
      ]);
    });
    this.minScore = minScore;
    this.source = source;
  },

  methods: {
    draw(id) {
      this.charts = echarts.init(document.getElementById(id));
      let vm = this;
      this.charts.on("click", function(params) {
        if (vm.user_exam_id !== params.data[3]) {
          vm.$router.push({
            name: "TestResult",
            params: {
              testType: "sat"
            },
            query: {
              user_exam_id: params.data[3]
            }
          });
        }
      });
      this.charts.setOption({
        legend: {},
        tooltip: {},
        dataset: {
          source: this.source //连接数据
        },
        xAxis: { type: "category" },
        yAxis: {
          // 这个地方如果需要在Y轴定义最大值就放开,如果需要根据数据自适应的话,就注释掉
          type: "value",
          max: this.score,
          min: this.minScore - 100,
          maxInterval: this.score * 0.2,
          minInterval: 1
        },
        grid: { left: 50, bottom: 50 },
        series: [
          {
            type: "bar", //表示这个是柱状图
            barCategoryGap: "40%",
            barWidth: 70,
            itemStyle: { color: "#43a06b" }, //定义颜色
            tooltip: {
              formatter: params => {
                return ` <b>${params.value[4]}: ${params.value[1]} <b/>`;
              }
            }
          }
        ]
      });
    }
  }
};
</script>
<style scoped>
.title {
  font-size: 30px;
  color: #43a06b;
}

</style>
